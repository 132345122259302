"use client";

import Image from "next/image";
import React, { useState } from "react";
import { cn } from "../../lib/utils";
import { AnimatedModal } from "../modal";
import { Url } from "url";
import { Card as CardType } from "../projects/Projects"

export const Card = React.memo(
  ({
    card,
    index,
    hovered,
    setHovered,
  }: {
    card: any;
    index: number;
    hovered: number | null;
    setHovered: React.Dispatch<React.SetStateAction<number | null>>;
  }) => (
    <div
      onMouseEnter={() => setHovered(index)}
      onMouseLeave={() => setHovered(null)}
      className={cn(
        "rounded-lg relative bg-neutral-900 overflow-hidden h-60 md:h-96 transition-all duration-300 ease-out",
        hovered !== null && hovered !== index && "blur-sm scale-[0.98]"
      )}
    >
      <Image
        src={card.src}
        alt={card.title + "screenshot"}
        fill
        className="object-cover absolute inset-0"
      />
      <div
        className={cn(
          "absolute inset-0 bg-black/50 flex items-end py-8 px-4 transition-opacity duration-300",
          hovered === index ? "opacity-100" : "opacity-0"
        )}
      >
        <div className="text-xl md:text-2xl font-medium bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-200">
          {hovered === index
            ? card.active
              ? card.title
              : "Coming soon ..."
            : card.title}
        </div>
      </div>
      {hovered !== index && (
        <div className="absolute inset-0 flex items-end py-8 px-4">
          <div className="text-xl md:text-2xl font-medium text-white">
            {card.active ? " " : card.title}
          </div>
        </div>
      )}
    </div>
  )
);


Card.displayName = "Card";


export function FocusCards({ cards }: { cards: CardType[] }) {
  const [hovered, setHovered] = useState<number | null>(null);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-10 max-w-5xl mx-auto md:px-8 w-full -mt-60">
      {cards.map((card, index) => (
        card.active ? (
          <AnimatedModal key={card.title} data={card}>
            <Card
              key={card.title}
              card={card}
              index={index}
              hovered={hovered}
              setHovered={setHovered}
            />
          </AnimatedModal>
        ) : (
          <div className="py-10" key={card.title}>
            <Card
              card={card}
              index={index}
              hovered={hovered}
              setHovered={setHovered}
            />
          </div>
        )
      ))}
    </div>
  );
}